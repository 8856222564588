(function($) {


    localforage.config({
        driver: [localforage.WEBSQL, localforage.INDEXEDDB, localforage.LOCALSTORAGE],
        name: 'spinoza-frontend'
    });

    localforage.getItem('last-update').then(function(value) {
        var now = new Date();
        if(value) {
            var date = new Date(value);
            var diff = Math.floor(Math.abs(now.getTime() - date.getTime()) / (1000 * 3600 * 24));

            //console.info('DB updated', diff, 'days ago');

            if(diff > 2) {
                localforage.clear().then(function() {
                    localforage.setItem('last-update', now.toISOString());
                });
            }
        } else {
            //console.info('DB never updated before');
            localforage.setItem('last-update', now.toISOString());
        }
    });

    function returnToLastOverviewPage() {
        localforage.getItem('last-overview-page')
            .then(function(url) {
                if(url) {
                    window.location = url;
                }
            });
    }

    window.setOverviewPage = function(url) {
        localforage.setItem('last-overview-page', url);
    };
    
    $(document).ready(function() {

        $('#close_search').click(function() {
            returnToLastOverviewPage();

            return false;
        });

        $('.subheader a.close-btn').click(function() {
            returnToLastOverviewPage();

            return false;
        });

        localforage.getItem('last-overview-page')
            .then(function(url){
                if(!url) {
                    $('.subheader a.close-btn').hide();
                }
            });
        
    });

})(jQuery);