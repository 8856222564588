(function($) {
    "use strict";

    var tpl = {};

    // do swig extending and compilation
    swig.setDefaults({
        varControls: ['[[', ']]'],
        tagControls: ['[%', '%]'],
        cmtControls: ['[#', '#]'],
        cache: {
            get: function(key) {
                return localforage.getItem('swig-cache-' + key);
            },
            set: function(key, value) {
                localforage.setItem('swig-cache-' + key, value);
            }
        },
        loader: {
            resolve: function(to, from) {
                return to;
            },
            load: function(identifier, cb) {
                var html = $('#' + identifier).html();
                if(typeof(cb) !== 'undefined') {
                    cb(html);
                }
                return html;
            }
        }
    });

    function setTemplates() {
        $('script[type=text\\/x-swig-template]').each(function() {
            var id = $(this).attr('id');
            var template = $(this).html();
            tpl[id] = swig.compile(template);
        });
    }

    window.Xim.Init.templates = function() {
        // add own filters
        var shorten = function($text, $length) {
            return Xim.Utils.Shorten($text, $length || 80, '...');
        };
        shorten.safe = true;
        swig.setFilter('shorten', shorten);

        var debug = function($text) {
            console.log('-- debug', $text);
            return '';
        };
        swig.setFilter('debug', debug);

        setTemplates();
    };

    window.Xim.Templates = {
        get: function(id) {
            return tpl[id];
        },
        set: function(id, template) {
            tpl[id] = template;
        }
    };

})(jQuery);