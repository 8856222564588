(function ($) {
    "use strict";

    window.Xim.Init.keywords = function() {
        // init
        $('[data-keyword]').each(Xim.Keywords.HandleKeyword);

        $('.related a').filter(function() {
            return $(this).find('.count').length == 0;
        }).click(function() {
            return false;
        }).end().filter(function() {
            return $(this).find('.count').length > 0;
        }).click(function() {
            if(Xim.Globals.MOBILE) {
                $('html, body').animate({
                    scrollTop: ($(document).height() + 1000)
                }, 2000);
            }
        });
    };

    var icons = {}, keywords = {};

    window.Xim.Keywords = {
        // actual class / object
        HandleKeyword: function() {
            // this = <a data-keyword href />
            var model = $(this).data('keyword');
            var id = parseInt($(this).attr('href').replace('#', ''), 10);
            var icon = Xim.Keywords.getIcon(model);
            if($(icon).find('.count').length == 0) {
                $(icon).append('<span class="count">1</span>');
                $(icon).click(function() {
                    Xim.Keywords.buildAccordion($(this).data('model'));
                    return false;
                });
            } else {
                var count = parseInt($(icon).find('.count').text());
                $(icon).find('.count').text(++count);
            }

            if(!keywords[model]) {
                keywords[model] = [];
            }
            keywords[model].push(id);

            $(this).click(function() {
                var path = Xim.Utils.Translate(model);
                window.location = '/' + path + '/' + id;
                return false;
            });

            $('.related').parents('.block').removeClass('hide');
        },
        getIcon: function(model) {
            if(typeof(icons[model]) !== 'undefined') {
                return icons[model];
            }
            return icons[model] = $('.related a').filter(function() {
                return $(this).data('model') == model;
            });
        },
        loadContents: function(model, id) {
            var m = Xim.Utils.Translate(model);
            return new Promise(function(resolve, reject) {
                $.get('/api/v1/' + m + '/' + id)
                    .fail(reject)
                    .success(resolve)
                    .done();
            })
        },
        buildAccordion: function(model) {
            var references = keywords[model], queue = [];

            $('.references .accordion').empty();

            references.forEach(function(id) {
                queue.push(
                    Xim.Keywords.loadContents(model, id)
                        .then(function(data) {
                            $('.references .accordion').append(Xim.Templates.get('reference_panel')({
                                id: id,
                                title: data.title,
                                content: data.content,
                                url: data.url
                            }));
                        })
                );
            });

            Promise.all(queue).then(function() {
                $('.references')
                    .find('.term').text(Xim.Utils.Translate(model)).end()
                    .removeClass('hide')
                    .collapse();
            });
        }
    };

})(jQuery);