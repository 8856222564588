(function($) {

    $(document).on('shown.bs.modal', function(eve) {
        setTimeout(function() {
            var video = $(eve.target).find('.carousel-inner:visible .item.active video');
            if(video.length) {
                video.data('mp').play();
                $(window).resize();
            }
        }, 500);
    });
    $(document).on('hide.bs.modal', function() {
        $('video').each(function() {
            var mp = $(this).data('mp');
            mp.pause();
            if(mp.media && mp.media.stop) {
                mp.media.stop();
            }
        });
        $('body').removeClass('box--active');
    });
    $(document).on('slid.bs.carousel', function(eve) {
        $(eve.target).find('.carousel-inner:visible .item.active video').each(function() {
            $(this).data('mp').play();
            $(this).parents('.play-button').hide();
        });
    });
    $(document).on('slide.bs.carousel', function(eve) {
        $(eve.target).find('.carousel-inner:visible .item.active video').each(function() {
            $(this).data('mp').pause();
        });
    });

    window.Xim.Init.media = function() {
        $('video').each(function() {
            var mp = new MediaElementPlayer('#' + this.id, {
                videoVolume: 'horizontal'
            });

            $(this).data('mp', mp);

            if($(this).find('source[type*=vimeo]').length > 0) {
                // add play button
                var parent = $(this).parents('.videocontent');
                var a = $('<a href="#" class="play-button"></a>');

                a.append(this);
                parent.append(a);

                a.click(function() {
                    a.hide();
                    mp.play();
                    return false;
                });
            }
        });
    };

})(jQuery);