
if (!window.Xim) {
    window.Xim = {};
}
if (!window.Xim.Init) {
    window.Xim.Init = {};
}
if (!window.Xim.Globals) {
    /**
     * @type {{}}
     * @property {bool} MOBILE
     */
    window.Xim.Globals = {};
}

var Xim = window.Xim;
Xim.init = function() {
    if(typeof(Xim.Init) !== 'undefined') {
        var keys = Object.keys(Xim.Init);

        keys.forEach(function(i) {
            if(typeof(Xim.Init[i]) == 'function') {
                Xim.Init[i].apply(Xim);
            }
        });
    }
};
Xim.Utils = {
    /**
     * @return {string}
     */
    Translate: function(model) {
        switch(model) {
            case 'App\\Models\\Person': return 'people';
            case 'App\\Models\\Work': return 'works';
            case 'App\\Models\\Letter': return 'letters';
            case 'App\\Models\\Location': return 'locations';
            case 'App\\Models\\Literature': return 'references';
            default:
                return model.replace('App\\Models\\', '').toLowerCase();
        }
    },
    Shorten: function($text, $length, $append) {
        if($text.indexOf('<p>') > -1) {
            var text = $('<div>'), count = $length;

            $('<div>' + $text + '</div>').find('p').each(function() {
                var t = $(this).text();

                if(t.length < count) {
                    text.append(this);
                    count -= t.length;
                } else if(count > 0) {
                    text.append('<p>' + Xim.Utils.Shorten(t, count, $append) + '</p>');
                    count = 0;
                }
            });

            return text.html();
        }
        return $text.length > $length ? $text.substring(0, $length - $append.length) + $append : $text;
    }
};

// mobile slider, only for small devices	
function setMobileSlider() {
    var w = $(window).width();
    var h = $(window).height();

    var mql = window.matchMedia('(max-width: 767px)');
    Xim.Globals.MOBILE = !!mql.matches;
}

$(document).ready(function () {

    "use strict";
    setMobileSlider();
    $('.no-script').remove();

    $('.search__input').each(function() {
        $(this).tagsinput({
            tagClass: 'search__tag',
            confirmKeys: [13, 32, 0],
            maxTags: 4,
            allowDuplicates: false,
            cancelConfirmKeysOnEmpty: true
        });
        $(this).data('tagsinput').inputSize = 10;
    });

    var makeScroll = function (element) {
        var scrollY = parseInt(80);
        $(window).scroll(function (e) {
            var scrollTop = $(window).scrollTop();
            var scrollFrom = scrollY;
            if (scrollTop > scrollFrom) {
                $(element).addClass("page--scrolling");
            }
            if (scrollTop <= scrollFrom) {
                $(element).removeClass("page--scrolling");
            }
        });
    };
    makeScroll('body');

    // if($('#miradorViewer').length) {
    //     var mirador = new Mirador({
    //         id: 'miradorViewer',
    //         // options
    //         bottomPanel: false,
    //         sidePanel: false,
    //         annotationLayer: true,
    //         annotationCreation: true,
    //         annotationState: 'annoOnCreateOn',
    //         fullScreen: true,
    //         buildPath: '/mir/',
    //         layout: '1x1',
    //         saveSession: true,
    //         annotationEndpoint: {
    //             name:"Local Storage",
    //             module: "LocalStorageEndpoint"
    //         },
    //
    //         // data
    //         data: [
    //
    //         ]
    //     })
    // }
});

$(window).bind('resize', function () {
    setMobileSlider();
});

$('.item__content').on('shown.bs.collapse', function (e) {
    $(".item__content").each(function () {
        if ($(this).attr('id') != e.currentTarget.id) {
            $(this).collapse('hide');
        }
    });
});

$(document).on('click', '.open--menu', function (e) {
    e.preventDefault();
    $(".open--menu").each(function () {
        $(this).addClass('close--menu');
        $(this).removeClass('open--menu');
    });
    $('body').addClass('menu--active');
});

$(document).on('click', '.close--menu, .fe--close--menu', function (e) {
    e.preventDefault();
    $(".close--menu").each(function () {
        $(this).addClass('open--menu');
        $(this).removeClass('close--menu');
    });
    $('body').removeClass('menu--active');
});

$(document).keyup(function (e) {
    var body = $('body');
    if (e.keyCode == 27) {
        if (body.hasClass('menu--active')) {
            $(".close--menu").each(function () {
                $(this).addClass('open--menu');
                $(this).removeClass('close--menu');
            });
            body.removeClass('menu--active');
        }
    }
});

$('.content').click(function() {
    if ($('body').hasClass('menu--active')) {
        $(".close--menu").each(function () {
            $(this).addClass('open--menu');
            $(this).removeClass('close--menu');
        });
        $('body').removeClass('menu--active');
    }
});

$(document).on('show.bs.modal', function() {
    $('body').addClass('box--active');
});
$(document).on('shown.bs.collapse', function(e) {
    var scrollTop = $(e.target).offset().top;

    if(Xim.Globals.MOBILE) {
        setTimeout(function () {
            $(window).scrollTop(scrollTop - 50);
        }, 250);
    }
});