var data = '';
var children = '';

var mousedown = false;
var dragging = false;
var yContainer = 0;
var currentPosition = 0;
var margin = -180;

var image1top = 0;
var image2top = 0;

var goto = "";

var currentId;

$(document).ready(function () {
    if ($('.timeline').length > 0) {

        init();

        $('.cancel').click(function () {
            $('.content-tooltip').addClass('hide');
        });
        $('.go').click(function () {
            location.href = goto;
        });

        $('.exit').click(function () {
            $('.imaged-overlay').addClass('hide');
            $('.page-overlay').addClass('hide');
        });

        $('.scrollUp').click(scrollUp);
        $('.scrollDown').click(scrollDown);

        $('.sidebar').mousedown(function (e) {
            mousedown = true;
            yContainer = e.pageY;
            dragging = false;
        });
        $('.sidebar').mousemove(function (e) {
            if (mousedown) {

                if (yContainer > e.pageY + 5) {
                    yContainer = e.pageY;
                    margin += 15;
                    var tempPos = Math.round(margin / 96) + 2;
                    recalculateTimelineClassesByPos(tempPos);
                    $('.sidebar').css({'margin-top': -margin});
                    if (!dragging) {
                        $('.black-overlay').removeClass('hide');
                        $('.black-overlay').animate({'opacity': 1}, 300);
                        dragging = true;
                    }
                }
                else if (yContainer < e.pageY - 5) {
                    yContainer = e.pageY;
                    margin -= 15;
                    var max = ($('.item').outerHeight() + 5) * 2;
                    if (margin <= -max)
                        margin = -max;
                    var tempPos = Math.round(margin / 96) + 2;
                    recalculateTimelineClassesByPos(tempPos);
                    $('.sidebar').css({'margin-top': -margin});
                    if (!dragging) {
                        $('.black-overlay').removeClass('hide');
                        $('.black-overlay').animate({'opacity': 1}, 300);
                        dragging = true;
                    }
                }
            }
        });
        $(document).mouseup(function (e) {
            mousedown = false;
        });
        $('.sidebar').on('touchstart', function (e) {
            mousedown = true;
            dragging = false;
        });
        $('.sidebar').on('touchmove', function (e) {
            e.preventDefault();
            var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
            if (mousedown) {

                if (yContainer > touch.pageY + 20) {
                    yContainer = touch.pageY;
                    margin += 25;
                    var tempPos = Math.round(margin / 96) + 2;
                    recalculateTimelineClassesByPos(tempPos);
                    $('.sidebar').css({'margin-top': -margin});
                    if (!dragging) {
                        $('.black-overlay').removeClass('hide');
                        $('.black-overlay').animate({'opacity': 1}, 300);
                        dragging = true;
                    }
                }
                else if (yContainer < touch.pageY - 20) {
                    yContainer = touch.pageY;
                    margin -= 25;
                    var max = ($('.item').outerHeight() + 5) * 2;
                    if (margin <= -max)
                        margin = -max;
                    var tempPos = Math.round(margin / 96) + 2;
                    recalculateTimelineClassesByPos(tempPos);
                    $('.sidebar').css({'margin-top': -margin});
                    if (!dragging) {
                        $('.black-overlay').removeClass('hide');
                        $('.black-overlay').animate({'opacity': 1}, 300);
                        dragging = true;
                    }
                }
            }
        });
        $('.sidebar').on('touchend', function () {
            mousedown = false;
        });
        $('.black-overlay').click(function () {
            $('.black-overlay').animate({'opacity': 0}, 300, function () {
                $('.black-overlay').addClass('hide');
            });
            $(".item").removeClass(function (index, css) {
                return (css.match(/(^|\s)distance-\S+/g) || []).join(' ');
            });
            $(".timeline-fill").removeClass(function (index, css) {
                return (css.match(/(^|\s)distance-\S+/g) || []).join(' ');
            });
            recalculateTimelineClasses();
            var ih = $('.item').outerHeight();
            margin = (ih + 5) * (currentPosition - 2);
            $('.sidebar').stop().animate({"margin-top": -margin}, 300);
            dragging = false;
        });


        // $(document).on('click', '.item', function () {
        //     if (!$(this).hasClass('selected') && !dragging)
        //         outro($(this));
        // });

        $(document).on('click', '.item-container', function () {
            var target = $(this).find('.item');
            if (!target.hasClass('selected') && !dragging) {
                outro(target);
            }
        });

        $('.imaged-overlay').click(function (e) {
            if ($(e.target).hasClass('overlay-image')) {
                e.preventDefault();
                return;
            }
            else if ($(e.target).hasClass('enlarge')) {
                e.preventDefault();
                showChild();
                return;
            }
            $(this).addClass('hide');
            $('#v2').addClass('hide');
        });


        $('.timeline-image').click(function () {
            $('.overlay-image').attr("src", $(this).attr("src"));
            $('.overlay-tooltip').html($(this).attr("alt"));
            $('.imaged-overlay').removeClass('hide');
            $('#v2').addClass('hide');
        });
        $('.timeline-image2').click(function () {
            $('.overlay-image').attr("src", $(this).attr("src"));
            $('.overlay-tooltip').html($(this).attr("alt"));
            $('#v2').removeClass('hide');
        });

        $(window).resize(function () {
            setHeights(true);
        });
    }
});

function init() {
    $.ajax({
        type: 'GET',
        url: '/api/v1/timeline',
        //async:false,
        success: function (text) {
            data = text;
        }
    }).done(function () {
        buildTimeline(data);
        updateStamp(currentPosition);
        setHeights(true);
        $('.black-overlay').css({'opacity': 0});
        $('.scrollUp').hide();
    });
}

var height = 0;

function setHeights(incl_images) {

    var windowHeight = $(window).height();
    var windowWidth = $(window).width();

    var content = $('.timeline');
    var menuHeight = $('header').outerHeight();
    height = windowHeight - menuHeight;
    content.css({'height': height});

    var target = $('.information-container');
    var footerHeight = $('.timeline-footer').outerHeight(true);
    height = windowHeight - target.offset().top - footerHeight - 15;
    target.css({'max-height': height});


    scrollbar();
    var ih = $('.item').outerHeight();
    var margin = (ih + 5) * (currentPosition - 2);
    $('.sidebar').css({"margin-top": -margin});
    var item = data[currentId];
    //console.log(item);

    if(incl_images === true) {
        ['side1', 'side2', 'side3', 'footer'].forEach(function (label) {
            var image = item.block['image_' + label];
            var element;
            switch (label) {
                case 'side1':
                    element = $('#side1');
                    break;
                case 'side2':
                    element = $('#scaleContainer2');
                    break;
                case 'side3':
                    element = $('#scaleContainer3');
                    break;
                case 'footer':
                    element = $('#scaleContainer-footer-image');
                    break;
            }

            //console.info('-- fe:image', image, typeof(image), image == null);

            if (typeof(image) !== 'undefined' && image != null) {
                if (windowWidth > 1240) {
                    if (image.width != null && image.width != 0) {
                        if (image.height == null || image.height == 0) {
                            element.css({
                                width: image.width,
                                height: 'auto'
                            });
                        } else {
                            element.css({
                                width: image.width,
                                height: image.height
                            });
                        }
                    } else if (image.height != null && image.height != 0) {
                        element.css({
                            width: 'auto',
                            height: image.height
                        });
                    }
                    if (image.y != null && image.y != 0) {
                        if (image.x == null || image.x == 0) {
                            element.css({
                                'margin-top': image.y,
                                'margin-left': 0
                            });
                        } else {
                            element.css({
                                'margin-top': image.y,
                                'margin-left': image.x
                            });
                        }
                    } else {
                        element.css({
                            'margin-top': 0,
                            'margin-left': image.x
                        });
                    }
                } else {
                    if (image.width_small != null && image.width_small != 0) {
                        if (image.height_small == null || image.height_small == 0) {
                            element.css({
                                width: image.width_small,
                                height: 'auto'
                            });
                        } else {
                            element.css({
                                width: image.width_small,
                                height: image.height_small
                            });
                        }
                    } else if (image.height_small != null && image.height_small != 0) {
                        element.css({
                            width: 'auto',
                            height: image.height_small
                        });
                    }
                    if (image.y_small != null && image.y_small != 0) {
                        if (image.x_small == null || image.x_small == 0) {
                            element.css({
                                'margin-top': image.y_small,
                                'margin-left': 0
                            });
                        } else {
                            element.css({
                                'margin-top': image.y_small,
                                'margin-left': image.x_small
                            });
                        }
                    } else {
                        element.css({
                            'margin-top': 0,
                            'margin-left': image.x_small
                        });
                    }
                }
            }
        });
    }
}
var currentDate = "";
// @FIXME Replace with template
function buildTimeline(data) {
    var html = "";
    data.forEach(function (item, i) {
        html += "<tr class='item-container'>";
        if (i == 0)
            if (i == currentPosition)
                html += "<td class='item first selected' position='" + i + "'>";
            else
                html += "<td class='item first' position='" + i + "'>";
        else if (i == currentPosition)
            html += "<td class='item selected' position='" + i + "'>";
        else
            html += "<td class='item' position='" + i + "'>";
        currentDate = item.alt_date;
        html += "<p class='date'>" + currentDate + "</p>";
        html += "<p class='location'>" + item.city.toUpperCase() + "</p>";
        html += "<p class='information'>" + item.title.toUpperCase() + "</p>";

        html += "</td><td class='timeline-fill' position='" + i + "'></td></tr>";

        if (i != data.length - 1)
            html += "<tr><td class='grey-line'></td><td class='black-line'></td></tr>"
    });
    $('.sidebar').html(html);
    recalculateTimelineClasses();
}

function dateFormat(date) {
    var monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];

    var formatted = date.slice(0, -16);
    var year = formatted.slice(0, 4);
    var month = monthNames[parseInt(formatted.slice(5, 7)) - 1];
    var day = formatted.slice(8, 10);
    return day + " " + month + " " + year;
}
var speed = 350;

function scrollTo(item) {
    //update classes
    $('.imaged-overlay').addClass('hide');
    $('.page-overlay').addClass('hide');
    $('.item').removeClass('selected');
    $(".item").removeClass(function (index, css) {
        return (css.match(/(^|\s)distance-\S+/g) || []).join(' ');
    });
    $(".timeline-fill").removeClass(function (index, css) {
        return (css.match(/(^|\s)distance-\S+/g) || []).join(' ');
    });

    //calculate & move sidebar elements
    currentPosition = item.attr('position');
    var itemHeight = item.outerHeight();
    margin = (itemHeight + 5) * (item.attr('position') - 2);

    $('.sidebar').animate({"margin-top": -margin}, speed, function () {
        updateStamp(currentPosition);
        item.addClass('selected');
        recalculateTimelineClasses();
    });
}

function moveToCurrent() {
    var itemHeight = $('.item').outerHeight();
    var margin = (itemHeight + 5) * (currentPosition - 2);
    $('.sidebar').css({'margin-top': -margin});
}

function recalculateTimelineClasses() {
    $('.item').each(function () {
        var difference = Math.abs($(this).attr('position') - currentPosition);
        $(this).addClass('distance-' + difference);
    });
    $('.timeline-fill').each(function () {
        var difference = Math.abs($(this).attr('position') - currentPosition);
        $(this).addClass('distance-' + difference);
    });
}
function recalculateTimelineClassesByPos(pos) {
    $(".item").removeClass(function (index, css) {
        return (css.match(/(^|\s)distance-\S+/g) || []).join(' ');
    });
    $(".timeline-fill").removeClass(function (index, css) {
        return (css.match(/(^|\s)distance-\S+/g) || []).join(' ');
    });
    $('.item').each(function () {
        var difference = Math.abs($(this).attr('position') - pos);
        $(this).addClass('distance-' + difference);
    });
    $('.timeline-fill').each(function () {
        var difference = Math.abs($(this).attr('position') - pos);
        $(this).addClass('distance-' + difference);
    });
}

function changePalette(palette) {
    $('.timeline').removeClass(function (index, css) {
        return (css.match(/(^|\s)palette-\S+/g) || []).join(' ');
    });
    $('.timeline').addClass(palette);
}

function updateStamp(id) {

    var url;
    var item = data[id];
    changePalette(item.theme);
    currentId = id;
    //update page content
    $('#textarea').html(bbText(item.block.content));
    $('#timeline-title').html(item.block.title);
    if (item.block.sidenote != null && item.block.sidenote != "") {
        $('.bubble-container').show();
        $('#text-bubble').html(item.block.sidenote);
        $('#text-bubble').show();
    }
    else {
        $('.bubble-container').hide();
        $('#text-bubble').hide();
    }

    $('.footer-location').html(item.block.city);
    currentDate = item.alt_date;
    $.ajax({
        type: 'GET',
        url: '/api/v1/timeline/' + data[id].id,
        async: false,
        success: function (text) {
            children = text;
        }
    });
    //header image
    if (item.block.image_header) {
        url = 'uploads/media/' + item.block.image_header.id + "/";
        $('.timeline-header').css({'background-image': 'url(' + url + item.block.image_header.file_name + ')'});
        if (item.block.image_header.y != null && item.block.image_header.y != 0)
            $('.timeline-header').css({
                'background-attachment': 'fixed',
                'background-position-y': item.block.image_header.y + 'px',
                'background-size': '100%'
            });
    }
    else {
        $('.timeline-header').css({'background-image': 'url("#")'});
    }
    //footer image
    if (item.block.image_footer) {
        url = 'uploads/media/' + item.block.image_footer.id + "/";
        $('#footer-image').attr('src', url + item.block.image_footer.file_name);
        $('#footer-image').attr('alt', item.block.image_footer.title);

        $('#footer-image').show();
    }
    else {
        $('#footer-image').attr('src', "");
        $('#footer-image').attr('alt', "");
        $('#footer-image').hide();
    }
    //image 1
    if (item.block.image_side1) {
        url = 'uploads/media/' + item.block.image_side1.id + "/";
        $('#side1').attr('src', url + item.block.image_side1.file_name);
        $('#side1').attr('alt', item.block.image_side1.title);

        $('#side1').show();
    }
    else {
        $('#side1').attr('src', "");
        $('#side1').attr('alt', "");
        $('#side1').hide();
    }
    //image 2side
    if (item.block.image_side2) {
        url = 'uploads/media/' + item.block.image_side2.id + "/";
        $('#side2').attr('src', url + item.block.image_side2.file_name);
        $('#side2').attr('alt', item.block.image_side2.title);

        $('#side2').show();
    }
    else {
        $('#side2').attr('src', "");
        $('#side2').attr('alt', "");
        $('#side2').hide();
    }
    //image 3
    if (item.block.image_side3) {
        url = 'uploads/media/' + item.block.image_side3.id + "/";
        $('#side3').attr('src', url + item.block.image_side3.file_name);
        $('#side3').attr('alt', item.block.image_side3.title);

        $('#side3').show();
    }
    else {
        $('#side3').attr('src', "");
        $('#side3').attr('alt', "");
        $('#side3').hide();
    }
    //wait and tween back in
    setTimeout(intro, 350);
}

function bbText(text) {
    var id = "";
    while (text.indexOf('[ContentLink:') != -1) {
        var preString = '[ContentLink:';
        var searchString = ']';
        var preIndex = text.indexOf(preString);
        var searchIndex = preIndex + text.substring(preIndex).indexOf(searchString);
        id = text.slice(text.indexOf('[ContentLink:') + 13, searchIndex);

        text = [text.slice(0, text.indexOf("[ContentLink:")), '<a href="#" class="linkToContent" link="' + id + '">', text.slice(text.indexOf("[ContentLink:"))].join('');
        text = [text.slice(0, text.indexOf("[/ContentLink]")), '</a>', text.slice(text.indexOf("[/ContentLink]"))].join('');
        text = [text.slice(0, text.indexOf("[ContentLink:")), text.slice(text.indexOf("[ContentLink:") + 14 + id.length)].join('');
        text = [text.slice(0, text.indexOf("[/ContentLink]")), text.slice(text.indexOf("[/ContentLink]") + 14)].join('');

    }
    return text;
}

function outro(item) {
    $('.scrollUp').hide();
    $('.scrollDown').hide();
    loaded = false;
    var textElements = $('.fade-text');
    image1top = $('#image2').css('top');
    image2top = $('#image3').css('top');

    textElements.stop().animate({opacity: 0}, speed, function () {
        $('.timeline-information').stop().animate({width: 0}, speed);
        $('.timeline-title').stop().animate({width: 0}, speed + 100);
        $('.text-bubble').stop().animate({'top': -200}, speed + 100);
        $("#image1").stop().animate({'top': -400}, speed);
        $("#image2").stop().animate({'right': -500, 'top': -400}, speed);
        $("#image3").stop().animate({'right': -500, 'top': 1500}, speed);
        $(".footer-image").stop().animate({'bottom': -700}, speed + 100, function () {
            $('.black-overlay').animate({'opacity': 0}, 200, function () {
                $('.black-overlay').addClass('hide');
            });
            scrollTo(item);
        });
    });
    dragging = false;

}
var textTop = 0;
var scrollStep = 30;

function intro() {
    var textElements = $('.fade-text');

    setHeights(true);

    $('.timeline-title').stop().animate({width: '100%'}, speed);
    $('.text-bubble').stop().animate({'top': 0}, speed);
    $('.timeline-information').stop().animate({width: '100%'}, speed + 100);
    $("#image1").stop().animate({'top': 0}, speed);
    $("#image2").stop().animate({'right': 0, 'top': 0}, speed);
    $("#image3").stop().animate({'right': 0, 'top': 0}, speed);
    $(".footer-image").stop().animate({'bottom': 0}, speed, function () {
        textElements.stop().animate({opacity: 1}, speed);
        $('.scrollUp').show();
        $('.scrollDown').show();

        $(window).trigger('resize');
    });
    $('.linkToContent').click(function () {
        showChild($(this).attr('link'));
    });

    loaded = true;
}

function scrollUp() {
    var text = $('#textarea');
    textTop += scrollStep;
    if (textTop >= 0) {
        textTop = 0;
        $('.scrollUp').hide();
    }
    $('.scrollDown').show();
    text.css({'margin-top': textTop});
}

function scrollDown() {
    var text = $('#textarea');
    textTop -= scrollStep;
    if (text.outerHeight() + textTop <= height) {
        textTop = height - text.outerHeight();
        $('.scrollDown').hide();
    }
    $('.scrollUp').show();
    text.css({'margin-top': textTop});
}

function scrollbar() {
    var text = $('#textarea');
    var scrollbar = $('.controls');

    textTop = 0;
    text.css({'margin-top': 0});
    $('.scrollUp').hide();
    $('.scrollDown').show();
    if (text.outerHeight() < height) {
        scrollbar.css({'visibility': 'hidden', 'height': 1});
    }
    else {
        scrollbar.css({'visibility': 'visible'});
        $('.controls').css({'height': height - 5});
    }
}

function showChild(id) {
    $.each(children.children, function (i, c) {
        if (c.id == id) {
            updatePage(c);
            return false;
        }
    });
    $('.linkToContent').click(function () {
        showChild($(this).attr('link'));
    });
}

function updatePage(child) {
    //hello
    $('#page-content').html(child.content);
    $('.page-title').html(child.title);
    var images = "";
    $("#content-image").attr("src", "");
    $("#content-image").attr("alt", "");
    $(".credits").html("");

    var array = [];
    $.each(child.media, function (i, m) {
        array.push(m);
    });
    array.sort(function (a, b) {
        return a.order > b.order;
    });
    // @FIXME Replace with template
    $.each(array, function (i, m) {
        if (i == 0) {
            $("#content-image").attr("src", m.url);
            $("#content-image").attr("alt", m.description);
            $(".credits").html(m.description);
        }
        else {
            images += '<img class="content-image" src="' + m.url + '" alt="' + m.description + '"/>';
        }
    });
    $('.images-container').html(images);
    var items = "";
    $.each(children.children, function (i, c) {
        if (c.id != child.id) {
            items += "<li><a href='#' class='linkToContent' link='" + c.id + "'>" + c.title.toUpperCase();
            +"</a></li>";
        }
    });
    $(".more-from").html("more from " + currentDate + ":");
    $('.link-list').html(items);

    $('#page-content').find('p a').click(function (e) {
        var tooltip = $('.content-tooltip');
        var path = Xim.Utils.Translate($(this).attr('data-keyword'));
        var id = parseInt($(this).attr('href').replace('#', ''), 10);

        localforage.getItem('remember_leaving_timeline').then(function(remember) {

            e.preventDefault();
            goto = "/" + path + "/" + id;

            if(remember) {
                window.location = goto;
            } else {
                tooltip.removeClass('hide');
            }
        });

        return false;
    });

    $('#remember_i_will_leave').change(function() {
        var checked = this.checked;
        localforage.setItem('remember_leaving_timeline', checked ? 'on' : 'off');
    });

    $('.imaged-overlay').addClass('hide');
    $('.page-overlay').removeClass('hide');

    $('.content-image').click(function () {
        var src = $('#content-image').attr('src');
        var alt = $('#content-image').attr('alt');
        $('#content-image').attr('src', $(this).attr('src'));
        $('#content-image').attr('alt', $(this).attr('alt'));
        if ($(this).attr('alt') != null)
            $('.credits').html($(this).attr('alt'));
        else
            $('credits').html("");
        $(this).attr('src', src);
        $(this).attr('alt', alt);

    });
}