/**
 * Created by jurriendokter on 10/03/16.
 */
(function ($) {
    "use strict";

    var highlight = function($input, $term, $length) {
        var h;

        if($term) {

            try {
                $term = typeof($term.join) !== 'undefined' ? $term.join('') : $term;

                h = Xim.Highlight($input, $term.split(','), $length || 80, '...');
                return h;
            } catch (e) {
            }
        } else if($input.length > ($length || 80)) {
            return $input.substr(0, ($length || 80) - 3) + '...';
        } else {
            return $input;
        }
    };
    highlight.safe = true;
    swig.setFilter('highlight', highlight);

    var filters = {
        models: {
            letters: {
                active: false,
                fields: {
                    title: 'title',
                    subtitle: 'subtitle',
                    description: 'searchtext'
                }
            },
            works: {
                active: false,
                fields: {
                    title: 'title',
                    subtitle: false,
                    description: 'searchtext'
                }
            },
            locations: {
                active: false,
                fields: {
                    title: 'name',
                    subtitle: false,
                    description: 'searchtext'
                }
            },
            people: {
                active: false,
                fields: {
                    title: 'title',
                    subtitle: 'subtitle',
                    description: 'searchtext'
                }
            }
        },
        count: function() {
            var c = 0;
            Object.keys(filters.models).forEach(function(i) {
                if(filters.models[i].active) {
                    ++c;
                }
            });
            return c;
        }
    };

    function showColumnsBasedOnFilters() {
        Object.keys(filters.models).forEach(function(i) {
            if(filters.models[i].active || filters.count() == 0) {
                $('.summarized-results .results--' + i).removeClass('hide');
            } else {
                $('.summarized-results .results--' + i).addClass('hide');
            }
        });
    }

    function showLargeResults() {
        $('.summarized-results').addClass('hide');
        $('.large-results').removeClass('hide');
    }

    function hideLargeResults() {
        $('.summarized-results').removeClass('hide');
        $('.large-results').addClass('hide').empty();
    }

    function showSmallSearchResults(model, data, term) {
        $('.summarized-results .results--' + model).empty()
            .html(Xim.Templates.get('search_result')({
                results: data.rows.slice(0, 10),
                total: data.rows.length,
                term: term,
                model: model,
                opt: filters.models[model]
            })).removeClass('hide');
    }

    function showLargeSearchResults(model, data, term) {
        /** @var {Array */
        var rows = data.rows, that = $('.filters__list .filters__item a[data-type=' + model + ']');

        $('.large-results').empty()
            .html(Xim.Templates.get('large_search_result')({
                results: {
                    left: rows.slice(0, Math.ceil(rows.length / 2)),
                    right: rows.slice(Math.ceil(rows.length / 2))
                },
                term: term,
                model: model,
                opt: filters.models[model]
            }));

        // handle
        Object.keys(filters.models).forEach(function(index) {
            if(index == model) {
                filters.models[index].active = !filters.models[index].active;
                if(filters.models[index].active) {
                    $(that).addClass('current--filter');
                } else {
                    $(that).removeClass('current--filter');
                }
            }
        });
    }

    function updateFilter(model, results) {
        var filter = $('.filters__list .filters__item a[data-type=' + model + ']');
        if(!results) {
            filter.addClass('turned-off');
        } else {
            filter.removeClass('turned-off');
        }
    }

    function setBindings() {
        $('.filters__list .filters__item a').each(function() {
            $(this).off('click').click(function() {
                var model = $(this).data('type'), that = this;

                if($(this).is('.turned-off')) {
                    $(this).blur();
                    return false;
                }

                // handle
                Object.keys(filters.models).forEach(function(index) {
                    if(index == model) {
                        filters.models[index].active = !filters.models[index].active;
                        if(filters.models[index].active) {
                            $(that).addClass('current--filter');
                        } else {
                            $(that).removeClass('current--filter');
                        }
                    }
                });

                if(filters.count() == 4) {
                    Object.keys(filters.models).forEach(function(index) {
                        filters.models[index].active = false;
                    });
                    $('.filters__list .filters__item a').removeClass('current--filter');
                }

                $(this).blur();
                $('.search .form').submit();
                return false;
            });
        });

        $('.search .form').each(function() {
            var form = $(this);
            form.submit(function() {
                var search_value = form.get(0).search.value;
                var term = search_value.split(', ');

                $('body').removeClass('menu--active');

                if(!search_value) {
                    return false;
                }
                if(window.location.pathname.substr(0, '/search'.length) !== '/search') {
                    window.location = '/search/' + term.join(',');
                    return false;
                }

                // update history
                history.pushState({}, 'Search results for : ' + search_value, '/search/' + term.join(','));
                window.setOverviewPage('/search/' + term.join(','));

                $('.subheader__title .term').text(search_value.split(',').join(', '));

                $('.search-page').removeClass('hide');
                showColumnsBasedOnFilters();

                Object.keys(filters.models).forEach(function(i) {
                    var model = filters.models[i];

                    if(model.active && filters.count() == 1) {
                        Xim.Search(term, i, function (data) {
                            showLargeResults();
                            showLargeSearchResults(i, data, term);
                        });

                    } else if(model.active || filters.count() == 0) {
                        Xim.Search(term, i, function (data) {
                            hideLargeResults();
                            showSmallSearchResults(i, data, term);
                            updateFilter(i, data.rows.length);
                        });
                    }
                });

                return false;
            })
        });

        $(document).on('click', '.summarized-results .show-all', function() {
            var that = this;
            var model = $(this).data('model');
            var search_value = $('.search .form [name=search]').val();
            var term = search_value ? search_value.split(', ') : '';

            $('.filters__list .filters__item a').removeClass('current--filter');

            showLargeResults();

            Xim.Search(term, model, function (data) {
                showLargeSearchResults(model, data, term);
            });

            return false;
        });

        $(document).on('click', '.large-results .show-all-types', function() {
            hideLargeResults();

            Object.keys(filters.models).forEach(function(index) {
                filters.models[index].active = false;
            });
            $('.filters__list .filters__item a').removeClass('current--filter');

            $(this).blur();
            $('.search .form').submit();

            return false;
        });
    }

    window.Xim.Init.search = function() {
        setBindings();

        var search_form = $('.search .form');

        if(search_form.get(0).search.value) {
            search_form.submit();
        }
    };

    window.Xim.Search = function(term, model, callback) {
        var cacheKey = [model, term].join('').toLowerCase().replace(/[^a-z0-9]+/g, '');
        var sterm = typeof(term.join) != 'undefined' ? term.join('') : term;

        localforage.getItem(cacheKey)
            .then(function(d) {
                if(!d) {
                    $.post('/_search', {
                        'search': sterm.split(','),
                        'model': model,
                        'per_page': 100
                    }, function(data) {
                        localforage.setItem(cacheKey, data)
                            .then(callback)
                            .catch(function(err) {
                                localforage.clear();
                            });

                    });
                } else {
                    callback(d);
                }
            })
            .catch(function(err) {
                localforage.clear();
            });
    };
    /**
     * @return {string}
     */
    window.Xim.Highlight = function($string, $term, $maxchars, $suffix) {
        if(!$term) {
            return $string;
        }

        if($term.join) { // is array
            $term = $term.join('|');
        }

        var reg = new RegExp('(.*?)(' + $term + ')(.{0,' + $maxchars + '})', 'igm'),
            matches,
            groups = [],
            lines = [],
            output,
            maxBefore,
            maxAfter,
            new_length,
            remove_for_dots;

        do {
            matches = reg.exec($string.replace(/&([a-zA-Z0-9]{2,6});/g, ''));
            if(matches) {
                groups.push([matches[1], matches[2], matches[3]]);
            }
        } while(matches && matches.length > 0);

        maxBefore = Math.max($maxchars / (groups.length * 2) - $term.length, 10);
        maxAfter = maxBefore;

        groups.forEach(function(group) {
            if(group[0].length > maxBefore) {
                group[0] = '...' + group[0].substr(0 - maxBefore - 3);
            }
            if(group[2].length > maxAfter) {
                group[2] = group[2].substr(0, maxAfter);
            }

            lines.push(group[0] + group[1] + group[2]);
        });

        output = lines.join('... ,');

        $maxchars += $.fn.occurrences(output, $term) * 13;
        output = output.replace(new RegExp('(.*?)(' + $term + ')(.*?)', 'igm'), '$1<mark>$2</mark>$3');

        if(output.length <= $maxchars) {
            return output;
        }

        new_length = $maxchars;
        while(output[new_length] != ' ') {
            if(!new_length) {
                new_length = $maxchars;
                break;
            }

            --new_length;
        }

        remove_for_dots = Math.max(0, new_length - $maxchars + 3);

        return output.substr(0, new_length - remove_for_dots) + $suffix;
    };

    window.Xim.Search.Clear = function() {
        $('.results__content').empty();
        $('.search__input').val('').tagsinput('removeAll');
    };

})(jQuery);